@import './variables';

@function _img($path) {
  @return #{$image-path}#{$path};
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@function loading-bg($color) {
  @if ($dots) {
    @return _img($dots);
  }
  @if (lightness($color) >= 30) {
    @return _img('background/loading.png'); // Lighter backgorund, return dark color
  } @else {
    @return _img('background/loading-white.png'); // Darker background, return light color
  }
}
