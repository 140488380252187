$c-primary: #009ed8;
$c-secandary: #009ed8;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #f78f24;
$dot-overrided: #f78f24;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-bottom: 1.5rem; //24
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 1.1;
    letter-spacing: -0.03125rem; //-0.5px
  }

  .description {
    text-align: left;
  }

  .animation-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: var(--page-background-color);
  }
}
