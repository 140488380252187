// App variables

// Images
$image-path: '/images/';

// Colors
$c-dark: #000;
$c-danger: #ed1c24;
$c-warning: #ffde00;
$c-green: #00c853;
$c-grey: #c7c7c7;
$c-grey-light: #f7f7f7;

$theme-colors: (
  primary: $c-primary,
  secondary: $c-secandary
);
