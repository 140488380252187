$c-primary: #009ed8;
$c-secandary: #009ed8;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #f78f24;
$dot-overrided: #f78f24;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: relative;
  @media all and (min-width: 500px) {
    text-align: center;
  }
  .title {
    margin-bottom: 25px;
    font-weight: 500;
  }

  label {
    @media all and (min-width: 500px) {
      display: block;
    }
  }

  .input {
    &.halfWith {
      max-width: 50%;
      margin-right: 15px !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
