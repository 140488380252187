$c-primary: #009ed8;
$c-secandary: #009ed8;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #f78f24;
$dot-overrided: #f78f24;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  overflow: auto;
  padding: 90px 25px 30px;

  h3 {
    margin-top: 25px;
    max-width: 180px;
    text-align: center;
  }

  button {
    max-width: 265px;
  }

  .image {
    display: -webkit-box;
    flex: 1;
    width: 100%;
    min-height: 120px;
    max-height: 280px;
    max-width: 320px;
    margin-top: 20px;
    overflow: initial !important;
    overflow-x: hidden !important;

    > div {
      height: 100%;
      overflow: initial !important;
      display: block;
      position: absolute !important;
    }

    img {
      display: inline-block;
      margin: auto;
      height: 100%;
    }
  }

  .dots {
    margin: 30px 0;
    padding: 0;

    li {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $c-grey;
      margin: 0 5px;

      &.active {
        background: var(--primary);
      }
    }
  }
}

@media (max-height: 635px) {
  .wrapper h3 {
    margin-top: 0;
  }
}

@media (max-height: 620px) {
  .wrapper .dots {
    margin: 10px 0;
  }
}

@media (max-height: 550px) {
  .wrapper .image {
    max-width: 260px;
    margin-top: 5px;
  }

  .wrapper .dots {
    margin: 15px 0;
  }
}

@media (max-height: 500px) {
  .wrapper .image {
    max-width: 230px;
  }
}
